<template>
  <!-- 查看全部弹窗 -->
    <el-dialog title="查看全部" :visible="isShowType" @close='close' width='500px'
    :destroy-on-close="true"
    :close-on-click-modal='false'>
        <el-table
          ref="addTable"
          class="global-table"
          v-loading="loading"
          :data="tableData">
          <el-table-column label="序号" prop="typeName" min-width="100" type="index" align='center'></el-table-column>
          <el-table-column label="填空内容" prop="fillAnswer" min-width="100" align='center'></el-table-column>
        </el-table>
        <global-pagination
          @size-change="loadData"
          @current-change="loadData"
          :current-page.sync="page"
          :page-size.sync="size"
          :total="total">
      </global-pagination>
    </el-dialog>
</template>

<script>
export default {
  typeName: 'DetailDialog',
  props: {
    isShowType: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      tableData: [],
      page: 1,
      size: 10,
      total: 0
    }
  },
  created () {
    this.loadData()
  },
  watch: {
  },
  methods: {
    loadData () {
      this.loading = true
      this.$axios.get(this.$apis.questionSurvey.selectGapFillingQuestionByPage, {
        questionId: this.id,
        page: this.page,
        size: this.size
      }).then(res => {
        this.total = res.total || 0
        this.tableData = res.list || []
      }).finally(() => {
        this.loading = false
      })
    },
    close () {
      this.$emit('update:isShowType', false)
    }
  }
}
</script>
<style scoped lang="scss">

</style>
