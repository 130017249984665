// 问卷调查
<template>
  <el-card>
    <div class="title">
      <h1>园内服务满意度调查</h1>
      <div>
        <span>发布日期：{{ tableData.publishedTime || '--'}}</span>
        &nbsp;&nbsp;&nbsp;
        <span>答卷数量：{{ tableData.numberOfAnswers  || 0 }}份</span>
      </div>
    </div>
    <el-table class="global-table" v-loading="loading" :data="tableData.questions" border :span-method="merge">
      <el-table-column label="问题序号" show-overflow-tooltip type="index" width="100" align="center"></el-table-column>
      <el-table-column label="标题" show-overflow-tooltip prop="questionTitle" min-width="150" align="center"></el-table-column>
      <el-table-column :label="'选项' + (index + 1) + '/数量/占比'" :prop="'choice' + index" v-for="(item, index) in optionCount" :key="index" align="center">
        <template v-slot="{ row }">
          <span v-if="row.questionType === '3'">
            {{row.fillAnswer || ''}}
            <span v-if="(row.fillAnswer && row.fillAnswer.length > 50)" class="link" @click="onDetail(row.questionId)">查看全部</span>
          </span>
          <span v-else>
            {{row['choice' + index]}}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 填空题查看内容 -->
    <DetailDialog v-if="isShowDetail" :isShowType.sync = 'isShowDetail' :id="questionId"></DetailDialog>
  </el-card>
</template>

<script>
import DetailDialog from './DetailDialog'
export default {
  name: 'QuestionsurveyDetail',
  components: {
    DetailDialog
  },
  data () {
    return {
      loading: false,
      page: 1,
      size: 10,
      total: 0,
      tableData: [],
      isShowDetail: false,
      optionCount: 0,
      questionId: ''
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    id () {
      return this.$route.params.id
    }
  },
  methods: {
    merge ({ row, column, rowIndex, columnIndex }) {
      // 填空题
      if (row.questionTitle.indexOf('填空题') !== -1) {
        if (columnIndex === 2) {
          return [1, this.optionCount]
        }
      }
    },
    onDetail (id) {
      this.isShowDetail = true
      this.questionId = id
    },
    loadData () {
      this.loading = true
      this.$axios.get(this.$apis.questionSurvey.selectQuestionDetail, {
        id: this.id
      }).then(res => {
        const newData = []
        // 处理数据
        res.questions.forEach(item => {
          newData.push({
            questionTitle: item.questionTitle + '  (' + (item.questionType === '1' ? '单选题' : item.questionType === '2' ? '多选题' : '填空题') + ')' + (item.isMustAnswer === '1' ? '  (必答)' : '')
          })
          item.answers.forEach((item1, index1) => {
            newData[newData.length - 1]['choice' + index1] = item.questionType === '3' ? '' : item1.choice + '/' + item1.score + '/' + item1.ratio
            newData[newData.length - 1].questionType = item.questionType
            newData[newData.length - 1].questionId = item.questionId || ''
            if (item.questionType === '3') {
              if (newData[newData.length - 1].fillAnswer) {
                newData[newData.length - 1].fillAnswer += item1.fillAnswer ? (index1 + 1) + '.' + (item1.fillAnswer + '；') : ''
              } else {
                newData[newData.length - 1].fillAnswer = item1.fillAnswer ? (index1 + 1) + '.' + (item1.fillAnswer + '；') : ''
              }
            }
          })
          if (item.answers.length > this.optionCount) {
            this.optionCount = item.answers.length
          }
        })
        this.tableData = res || []
        this.tableData.questions = newData
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.link{
  cursor: pointer;
  color:#1AD1B0;
  text-decoration:underline;
}
.title{
  text-align: center;
  padding: 86px 0px 50px 0px;
  h1{
    font-size: 28px;
    padding-bottom: 20px;
  }
  span{
    color: #9A9A9A;
  }
}
.el-table{
  margin: 0 100px;
  width: calc(100% - 200px);
}
</style>
